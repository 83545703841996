import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Data Work at Columbia`}</h1>
    <p>{`This set of six modules aims to prepare the learners for engaging with data in
their scholarly work. It covers the entirety of the research lifecycle and
pertains across disciplines. It aims to demonstrate and instruct that data
literacy moves beyond numeracy. Learners will gain literacy in how data
work—meaning both the work that data do when integrated into a research
project and the work internal to the data, which converts individual
observations into a whole with emergent properties.`}</p>
    <p>{`The work that data do in knowledge generation demand critical positioning
from the researcher. This positioning challenges the ways data has been and
continues to be mobilized to further inequity. As such, each module also has a
critical and ethical component that teaches learners to proceed justly.`}</p>
    <p>{`who owns the data?
where does it come from?`}</p>
    <h2>{`0. Data in the Research Lifecycle`}</h2>
    <ul>
      <li parentName="ul">{`teacher:`}</li>
      <li parentName="ul">{`software:`}</li>
      <li parentName="ul">{`ethics:`}</li>
      <li parentName="ul">{`critical skill:`}</li>
    </ul>
    <h2>{`1. Numbers as Data`}</h2>
    <ul>
      <li parentName="ul">{`teacher: Wei`}</li>
      <li parentName="ul">{`software: R?`}</li>
      <li parentName="ul">{`ethics:`}</li>
      <li parentName="ul">{`critical skill:`}</li>
    </ul>
    <h2>{`2. Space as Data`}</h2>
    <ul>
      <li parentName="ul">{`teacher: Eric`}</li>
      <li parentName="ul">{`software: QGIS`}</li>
      <li parentName="ul">{`ethics:`}</li>
      <li parentName="ul">{`critical skill:`}</li>
    </ul>
    <h2>{`3. Text as Data`}</h2>
    <ul>
      <li parentName="ul">{`teacher: Patrick`}</li>
      <li parentName="ul">{`software: nltk?`}</li>
      <li parentName="ul">{`ethics:`}</li>
      <li parentName="ul">{`critical skill:`}</li>
    </ul>
    <h2>{`4. Managing Data`}</h2>
    <ul>
      <li parentName="ul">{`teacher: Moacir`}</li>
      <li parentName="ul">{`software: Git`}</li>
      <li parentName="ul">{`ethics: labor recognition`}</li>
      <li parentName="ul">{`critical skill: reproducibility`}</li>
    </ul>
    <h2>{`5. Sharing and Presenting Data`}</h2>
    <ul>
      <li parentName="ul">{`teacher: Alex`}</li>
      <li parentName="ul">{`software: `}</li>
      <li parentName="ul">{`ethics:`}</li>
      <li parentName="ul">{`critical skill:`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      